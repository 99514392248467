<template>
  <div>
    <topbar drawer :title="navbarTitle"></topbar>
    <v-container v-if="!loading">
      <v-card shaped elevation="2">
        <v-list-item>
          <v-list-item-avatar size="80" color="white"><v-img :src="profile.image"></v-img></v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="text-h5 mb-1">
              <v-row dense>
                <v-col cols="12" sm="5">{{profile.name}}</v-col>
                <v-col cols="12" sm="7" class="d-flex">
                  <v-btn depressed small class="ml-auto" color="primary" tile @click="showHangar"><v-icon left>fa-eye</v-icon>Show Hangar</v-btn>
                </v-col>
              </v-row>
            </v-list-item-title>

            <v-list-item-subtitle class="mb-1">
              <v-row dense>
                <v-col v-if="profile.userId && user.userId === profile.userId || profile.userId && this.user.rank === 'Wildest One'" cols="12" sm="5">
                  Email: {{profile.email}}
                </v-col>
                <v-col v-if="profile.userId && user.userId !== profile.userId && this.user.rank !== 'Wildest One'" cols="12" sm="5">
                  Email: *********
                </v-col>
                <v-col cols="12" sm="7">
                  Registriert: {{profile.userId ? 'Ja' : 'Nein'}}
                </v-col>
              </v-row>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="mb-1">
              <v-row dense>
                <v-col cols="12" sm="5">
                  RSI Handle: {{profile.handle}}
                </v-col>
                <v-col cols="12" sm="7">
                  Rank: {{profile.rank}}
                </v-col>
              </v-row>
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="profile.discordId">
              <v-row dense>
                <v-col cols="12" sm="5">
                  <div v-if="user.userId === profile.userId || user.rank === 'Wildest One'">DiscordId: {{profile.discordId}}</div>
                  <div v-if="user.userId !== profile.userId && user.rank !== 'Wildest One'">DiscordId: DiscordId: *********</div>
                </v-col>
                <v-col v-if="profile.discordId" cols="12" sm="7">
                  <div>Discord name: {{profile.discordName}}</div>
                </v-col>
              </v-row>
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="user.userId === profile.userId || user.rank === 'Wildest One'">
              <v-row dense>
                <v-col cols="12" sm="5">
                  <div>Privater Hanger: {{profile.private ? 'Ja' : 'Nein'}}</div>
                </v-col>
              </v-row>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-container v-if="this.user.userId === this.profile.userId || this.user.rank === 'Wildest One'">
          <v-row dense>
            <v-col cols="12" sm="6">
              <v-btn depressed color="primary" tile @click="editDialog = true"><v-icon left>fa-edit</v-icon>Change user data</v-btn>
            </v-col>
            <v-col v-if="profile.userId" cols="12" sm="6">
              <v-btn depressed color="primary" tile @click="resetPassword"><v-icon left>fa-key</v-icon>Reset Password</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="this.user.rank === 'Wildest One'" dense>
            <v-col cols="12" sm="6">
              <v-btn depressed color="error" tile @click="deleteUser"><v-icon left>fa-edit</v-icon>Delete user</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <v-dialog v-model="editDialog" persistentmax-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Change user data</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <!-- <v-col v-if="profile.userId" cols="12">
                  <v-text-field v-model="newUserData.email" hint="The user will get a new verification email if you change it!" persistent-hint label="Users email"></v-text-field>
                </v-col> -->
                <v-col cols="12">
                  <v-text-field v-model="newUserData.handle" label="RSI handle"></v-text-field>
                </v-col>
                <v-col cols="12" v-if="user.rank === 'Wildest One'">
                  <v-text-field v-model="newUserData.discordId" label="DiscordId"></v-text-field>
                </v-col>
                <v-col v-if="user.userId === profile.userId" cols="12">
                   <v-checkbox dense
                    v-model="newUserData.private"
                    label="Privater Hanger"
                  ></v-checkbox>
                </v-col>
              </v-row>
          </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="editDialog = false">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="updateUserData">Update data</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
  import Topbar from '../components/Topbar';

  export default {
    name: 'Profile',

    components: {
      Topbar
    },

    props: {
      handle: {
        type: String,
        default: ''
      },
      userId: {
        type: String,
        default: ''
      }
    },

    data() {
      return {
        navbarTitle: 'Profile',
        editDialog: false,
        newUserData: {
          email: '',
          handle: '',
          discordId: '',
          private: false
        }      };
    },

    computed: {
      loading() {
        return this.$store.getters.loading;
      },
      user() {
        return this.$store.getters['user/getUser'];
      },
      profile() {
        return this.$store.getters['user/getProfile'];
      },
      logs() {
        return this.$store.getters['admin/getLogs'];
      }
    },

    watch: {
      editDialog(editDialog) {
        if (!editDialog) {
          this.newUserData = {
            email: '',
            handle: '',
            discordId: '',
            private: false
          };
          return;
        }
        this.newUserData = {
          email: this.profile.email,
          handle: this.profile.handle,
          discordId: this.profile.discordId,
          private: this.profile.private
        };
      },
      user(user) {
        this.privateHangar = user.private;
      },
      privateHangar(bool) {
        // if (bool !== this.user.private) {
        //   console.log('change');
        // }
      },
      $route (to){
        if (to.name === 'user') {
          this.loadItems();
        }
      }
    },

    beforeMount() {
      this.loadItems();
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    },

    methods: {
      async loadItems() {
        this.$store.dispatch('loading', true, {root: true});
        await this.$store.dispatch('user/getUser', this.$auth && this.$auth.user.sub);
        await this.$store.dispatch('user/getProfile', {userId: this.userId, handle: this.handle, rank: this.user.rank});
        this.$store.dispatch('loading', false, {root: true});
      },
      showHangar() {
        if (!this.profile.userId) {
          this.$swal({
            title: 'This user is not registered, so he does not have a hangar',
            icon: 'info'
          });
          return;
        }
        this.$store.dispatch('hangar/resetShipFilterStore');
        this.$router.push({name: 'hangar', query: {userId: this.profile.userId}});
      },
      async updateUserData() {

        // if (this.newUserData.email !== this.profile.email) {
        //   await this.$store.dispatch('user/updateEmail', {userId: this.profile.userId, email: this.newUserData.email});
        // }
        await this.$store.dispatch('user/updateUser', {oldHandle: this.profile.handle, discordId: this.newUserData.discordId, handle: this.newUserData.handle, private: this.newUserData.private, email: this.profile.email});
        // title: 'User data changed, if you also changed the email address, then the user has received a verification email',
        this.editDialog = false;
        this.$swal({
          toast: true,
          position: 'top-end',
          title: 'User data changed',
          icon: 'success',
          showConfirmButton: true
        });
        if (this.newUserData.handle !== this.profile.handle) {
          this.$router.push({name: 'user', query: {handle: this.newUserData.handle}});
        }
        this.loadItems();
      },
      resetPassword() {
        this.$swal({
          title: 'Reset User password?',
          icon: 'warning',
          showCancelButton: true,
          showLoaderOnConfirm: true,
          cancelButtonText: 'Nein',
          confirmButtonText: 'Ja',
          preConfirm: async () => {
            try {
              await this.$store.dispatch('user/resetPassword', this.profile.email);
              this.$swal({
                toast: true,
                timer: 3000,
                position: 'top-end',
                title: 'The user received a email to reset his password.',
                icon: 'success',
                showConfirmButton: true
              });
            } catch (error) {
              // NOOP
            }
          }
        });
      },
      async deleteUser() {
        this.$swal({
          title: 'Delete User?',
          icon: 'warning',
          showCancelButton: true,
          showLoaderOnConfirm: true,
          cancelButtonText: 'Nein',
          confirmButtonText: 'Ja',
          preConfirm: async () => {
            try {
              await this.$store.dispatch('user/deleteUser', {userId: this.profile.userId || '', handle: this.profile.handle});
              this.$router.push({name: 'dashboard'});
              this.$swal({
                toast: true,
                position: 'top-end',
                title: 'user deleted',
                icon: 'success',
                showConfirmButton: true
              });
            } catch (error) {
              // NOOP
            }
          }
        });
      },
      showError() {
        this.$swal({
          title: 'You can only update/backup every hour',
          icon: 'error'
        });
      },
      showSuccess() {
        this.$swal({
          toast: true,
          position: 'top-end',
          title: 'update/backup successful',
          icon: 'success',
          showConfirmButton: true
        });
      },
    }
  };
</script>
